
import { Options, Vue } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import FilterModel from "@/views/page/components/filter-model";
import getUTC from "@/function/getUTC";
import filter from "@/plugins/dataStore/Store";
import EndpointsResponseModel from "@/dataSource/api/analytic-endpoints/i-services/models/response/EndpointsResponseModel";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EndpointsRequest from "@/dataSource/api/analytic-endpoints/i-services/models/request/EndpointsRequest";
import EnumSortingType from "@/dataSource/api/common/models/enums/EnumSortingType";
import EndpointsResponse from "@/dataSource/api/analytic-endpoints/i-services/models/response/EndpointsResponse";

@Options({
  name: "MethodList",
  components: {
    Page,
    LogsViewFilter,
  },
})
/**
 * 
 * {
  "RequestId": "9b794183-ff21-0e63-9ac8-1e8f9aa35bdf",
  "DateStart": "2023-12-08T09:41:26.876981",
  "DateEnd": "2023-12-08T09:41:26.877012",
  "RequestLength": 4,
  "ResponseLength": 56,
  "ProjectLogGroupId": 191,
  "HttpMethod": 0,
  "Level": 0,
  "SessionToken": "9b794183-ff21-0e63-9ac8-1e8f9aa35bdf",
  "UserId": "12",
  "Url": "fwwe",
  "Ip": "sad wdqd",
  "RequestBody": "fasfasfas",
  "Message": "asdasdada",
  "Error": "zxczxcxzcz",
  "UserAgent": "hyjhtrht",
  "TimeoutWaitingEndLog": false,
  "ServerName": "test",
  "ProjectName": "test"
}
 * 
 */
export default class MethodList extends Vue {
  filter: FilterModel = new FilterModel();
  endpointsResponse: Array<EndpointsResponseModel> = [];
  pageResponse: PageResponse = new PageResponse();
  endpointsRequest: EndpointsRequest = new EndpointsRequest();
  sortName: string = "AverageTime";
  sortType: EnumSortingType = 1;
  isLoading: boolean = false;

  error = {
    isSuccess: true,
    errorMessage: "",
  };

  headerItemList = [
    {
      title: "#",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Название",
      sortName: "EndpointName",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Кол-во раз вызова",
      sortName: "NumberOfCalls",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Ср. t отклика",
      sortName: "AverageTime",
      defaultSort: "asc",
      isNotActiveSort: false,
    },
    {
      title: "Макс. t отклика",
      sortName: "MaxTime",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Мин. t отклика",
      sortName: "MinTime",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
  ];

  created() {
    this.filter = new FilterModel();
    this.filter.DateFrom = this.filter.getUTC(1);
  }

  async getData() {
    this.isLoading = true;
    let res: EndpointsResponse;
    
    this.endpointsRequest = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId || null,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,

      //       FromDate: moment(this.filter.DateFrom).format("MM.DD.YY HH:mm:ss"),
      // ToDate: moment(this.filter.DateTo).format("MM.DD.YY HH:mm:ss"),
      Page: { Skip: this.filter.SkipPage, Take: this.filter.TakePage },
      SortingModel: { Field: this.sortName, Type: this.sortType },
    };
    let tmpFilter = getUTC(this.endpointsRequest);
    try {
      res = await this.$api.AnalyticEndpointsService.getEndpointsAsync(tmpFilter);
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
      return;
    }
    this.endpointsResponse = res.Endpoints;
    this.pageResponse = res.Page;
    this.isLoading = false;
  }

  filterData() {
    this.filter.SkipPage = 0;
    (this.$refs.pagination as any).reset();
    this.getData();
  }

  changePage(page) {
    this.filter.SkipPage = (page - 1) * this.filter.TakePage;
    this.getData();
  }

  sort(name: string, type: number) {
    this.sortName = name;
    this.sortType = type;
    this.headerItemList.forEach((item) => {
      item.isNotActiveSort = item.sortName !== name;
    });
    this.getData();
  }
}
